<template>
  <section
    class="invoice-preview-wrapper"
    style="padding-bottom:20px;"
  >
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <!-- TODO:暂时不要 -->
          <!-- <b-button
            variant="relief-info"
            class="mr-1"
            @click="handleEdit"
          >
            编辑运单
          </b-button> -->
          <b-button
            v-if="detail.shipment_status !== 'voided' && !printLoading"
            variant="relief-success"
            @click="handlePrintModal"
          >
            打印运单
          </b-button>
          <b-button
            v-if="detail.shipment_status !== 'voided' && printLoading"
            variant="relief-success"
          >
            Loading...
          </b-button>
          <b-button
            v-if="isShowTrack"
            variant="relief-info"
            class="ml-1"
            @click="handleTrack"
          >
            点击追踪
          </b-button>
          <b-button
            v-if="detail.shipment_status === 'paid_agent'"
            variant="relief-danger"
            class="ml-1"
            @click="handleCancel"
          >
            取消运单
          </b-button>
        </div>
        <b-badge :variant="statusVariant(detail.shipment_status)">
          {{ detail.shipment_status_note }}
        </b-badge>
      </div>
    </b-card>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      opacity="1"
      blur="1rem"
    >
      <b-alert
        variant="danger"
        v-if="detail.is_include_battery"
        show
      >
        <div class="alert-body">
          <span><strong>该运单含有电池</strong></span>
        </div>
      </b-alert>
      <!-- detailFir -->
      <detail-fir
        :detail="detail"
        @handleCreateLineclear="handleCreateLineclear"
      />
      <detail-sec :detail="detail" />
      <waybill
        :detail="detail"
        :isLineclearLoading="isLineclearLoading"
        :printLineclear="printLineclear"
        @handlePrintModal="handlePrintModal"
        @handleCreateLineclear="handleCreateLineclear"
      />
      <declare :detail="detail" />
      <extra-service
        v-if="detail.extraServices && detail.extraServices.length"
        :shipment_status="detail.shipment_status"
        :extra-services="detail.extraServices"
      />
    </b-overlay>
    <log
      v-if="detail.ShipmentLogs && detail.ShipmentLogs.length"
      :shipmentLogs="detail.ShipmentLogs"
    />
    <remark
      v-if="detail.shipmentComments && detail.shipmentComments.length"
      :shipment-comments="detail.shipmentComments"
    />
    <b-modal
      ref="modal-print"
      cancel-variant="outline-secondary"
      centered
      title="打印"
    >
      <h2 class="text-center">
        您需要打印
      </h2>
      <div class="mt-3 mb-2">
        <b-form-group class="d-flex justify-content-center">
          <b-form-checkbox-group
            v-model="printSelected"
            :options="printOptions"
          />
        </b-form-group>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            class="float-right"
            @click="handlePrintAll"
          >
            全部打印
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-success"
            class="float-right ml-2"
            @click="handlePrint"
          >
            打印
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>
<script>
import {
  BCard, BButton, BOverlay, BBadge, BFormGroup, BFormCheckboxGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailFir from './components/detailFir.vue'
import DetailSec from './components/detailSec.vue'
import Waybill from './components/waybill.vue'
import Declare from './components/declare.vue'
import ExtraService from './components/extraService.vue'
import Log from './components/log.vue'
import Remark from './components/remark.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BOverlay,
    DetailFir,
    DetailSec,
    Waybill,
    Declare,
    ExtraService,
    Log,
    Remark,
    BBadge,
    BFormGroup,
    BFormCheckboxGroup,
    BAlert,
  },
  data() {
    return {
      id: '',
      show: true,
      selected: '',
      printSelected: [],
      printOptions: [
        { text: '打印运单', value: 'shipment' },
        { text: '打印申报单', value: 'declare' },
        { text: '打印Lineclear', value: 'lineclear' },
      ],
      printData: {},
      isLineclearLoading: false,
      printLineclear: false,
      printLoading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        voided: 'light-secondary',
        shipped: 'light-success',
        pay_now: 'light-danger',
        submitted: 'light-warning',
        packing: 'light-primary',
        paid_agent: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    isShowTrack({ detail }) {
      if (detail.trackData) {
        return detail.trackData.every(item => item.no && item.url)
      }
      return false
    },
    detail() {
      return this.$store.state.shipment.detail
    },
  },
  watch: {
    'detail.shipment_id': function (val) {
      // eslint-disable-next-line
      val ? this.show = false : this.show = true
    },
    printSelected: {
      handler() {
        this.handlePrintCheck()
      },
    },
  },
  created() {
    this.id = this.$route.params.id
    this.initState()
  },
  methods: {
    handleCreateLineclear() {
      this.$bvModal.msgBoxConfirm('确定创建Lineclear吗？', {
        title: '创建Lineclear',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.createLineClear()
        }
      })
    },
    async createLineClear() {
      try {
        this.isLineclearLoading = true
        const data = await this.$api.createLineClear({ shipment_id: this.detail.id })
        this.isLineclearLoading = false
        if (data.code === 200) {
          this.printLineclear = true
          this.$bvToast.toast('创建成功', {
            title: '创建成功',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
        }
      } catch (error) {
        this.isLineclearLoading = false
        console.log('error', error)
      }
    },
    async handlePrintModal() {
      this.printLoading = true
      const data = await this.$api.shipmentPrint({ id: this.id })
      this.printLoading = false
      this.printData = data.data
      if (data.code === 200) {
        const res = data.data
        if (res.declare) {
          if (res.declare.is_default) {
            this.printSelected = this.printSelected.concat('declare')
          } else {
            this.printSelected = this.printSelected.filter(item => item !== 'declare')
          }
        } else {
          this.printOptions = this.printOptions.filter(item => item.value !== 'declare')
        }
        if (res.shipment) {
          if (res.shipment.is_default) {
            this.printSelected = this.printSelected.concat('shipment')
          } else {
            this.printSelected = this.printSelected.filter(item => item !== 'shipment')
          }
        } else {
          this.printOptions = this.printOptions.filter(item => item.value !== 'shipment')
        }
        if (res.lineclear) {
          if (res.lineclear.is_default) {
            this.printSelected = this.printSelected.concat('lineclear')
          } else {
            this.printSelected = this.printSelected.filter(item => item !== 'lineclear')
          }
        } else {
          this.printOptions = this.printOptions.filter(item => item.value !== 'lineclear')
        }
      }
      this.$refs['modal-print'].show()
    },
    handleEdit() {
      this.$router.push(`/shipment/editdetail/${this.id}`)
    },
    handleTrack() {
      window.open(this.detail.trackData[0].url)
    },
    handlePrintAll() {
      this.printSelected = ['shipment', 'declare', 'lineclear']
      this.handlePrint()
    },
    handlePrintCheck() {
      if (this.printData.declare) this.printData.declare.isChoose = this.printSelected.includes('declare')
      if (this.printData.shipment) this.printData.shipment.isChoose = this.printSelected.includes('shipment')
      if (this.printData.lineclear) this.printData.lineclear.isChoose = this.printSelected.includes('lineclear')
      localStorage.setItem('print_data', JSON.stringify(this.printData))
    },
    async handlePrint() {
      try {
        if (this.printSelected.length === 0) {
          this.$bvModal
            .msgBoxConfirm('请选择打印项', {
              cancelVariant: 'outline-danger',
              centered: true,
              buttonSize: 'sm',
            })
          return
        }
        this.handlePrintCheck()
        this.printLineclear = false
        window.open('/print.html', '_blank')
      } catch (error) {
        console.log('error', error)
      }
    },
    initState() {
      this.$store.dispatch('shipment/getShipmentDetail', this.id)
    },
    handleCancel() {
      this.$bvModal.msgBoxConfirm(`确定取消${this.id}这个运单吗？`, {
        title: '取消运单',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.handleCancelShipment()
        }
      })
    },
    async handleCancelShipment() {
      try {
        const data = await this.$api.deleteShipment({ id: this.id })
        if (data.code === 200) {
          this.$bvToast.toast('取消成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.initState()
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss">
.badge{
  font-size: 20px;
  padding: 0.5rem 1rem;
}
</style>

<style lang="scss" scoped>
::v-deep .custom-checkbox .custom-control-label::before,::v-deep .custom-checkbox .custom-control-label::after{
  width: 22px !important;
  height: 22px !important;
}
</style>
