var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"rulesForm",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTw),expression:"isTw"}],staticClass:"mb-0",attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("根据台湾财政部关务署规定，请务必提醒收件人完成易利委（ezapp）实名认证，以确保包裹顺利清关，详情请"),_c('strong',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":_vm.handleGoEzapp}},[_vm._v("点击查看")])])])]),_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("请使用中文填写以下信息")])])])])],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"国家","label-for":"country","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country","disabled":_vm.isShipmentDetail || !_vm.isEdit,"placeholder":"请选择国家","options":_vm.countryList,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"邮编","label-for":"postcode"}},[_c('validation-provider',{attrs:{"name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","disabled":_vm.isShipmentDetail || !_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入邮编"},on:{"blur":_vm.handlePostcodeBlur},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.postError)?_c('small',{staticClass:"text-danger"},[_vm._v("查找不到对应的市区，请核对收件地址的邮编")]):_vm._e()]}}])})],1)],1),(_vm.isTw)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"区","label-for":"suburb"}},[_c('validation-provider',{attrs:{"name":"suburb","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"suburb","placeholder":"请选择区","disabled":_vm.isShipmentDetail || !_vm.isEdit,"options":_vm.suburbList,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text"},on:{"input":_vm.handleSuburbInput},model:{value:(_vm.form.suburb),callback:function ($$v) {_vm.$set(_vm.form, "suburb", $$v)},expression:"form.suburb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,282250814)})],1)],1):_vm._e(),(_vm.isTw)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"市","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"state","placeholder":"请选择市","disabled":_vm.isShipmentDetail || !!_vm.form.suburb || !_vm.isEdit,"options":_vm.stateList,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text"},on:{"input":_vm.handleStateInput},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2417683199)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"收件人名称","label-for":"recipient_name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"recipient_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"recipient_name","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入收件人名称"},model:{value:(_vm.form.recipient_name),callback:function ($$v) {_vm.$set(_vm.form, "recipient_name", $$v)},expression:"form.recipient_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"公司名称","label-for":"company_name"}},[_c('validation-provider',{attrs:{"name":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company_name","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入公司名称"},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isTw)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"市/区","label-for":"suburb"}},[_c('validation-provider',{attrs:{"name":"suburb","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isStateDisabled)?_c('b-form-input',{attrs:{"id":"suburb","disabled":_vm.isShipmentDetail || !_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入市/区"},model:{value:(_vm.form.suburb),callback:function ($$v) {_vm.$set(_vm.form, "suburb", $$v)},expression:"form.suburb"}}):_c('v-select',{attrs:{"id":"suburb","placeholder":"请选择市/区","options":_vm.suburbList,"disabled":_vm.isShipmentDetail || !_vm.isEdit,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text"},on:{"input":_vm.handleSuburbInput},model:{value:(_vm.form.suburb),callback:function ($$v) {_vm.$set(_vm.form, "suburb", $$v)},expression:"form.suburb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,289893849)})],1)],1):_vm._e(),(!_vm.isTw)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"省","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"state","placeholder":"请选择省","disabled":_vm.isShipmentDetail || _vm.isStateDisabled || !_vm.isEdit,"options":_vm.stateList,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text"},on:{"input":_vm.handleState},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2617495758)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"地址1","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入地址1"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"地址2","label-for":"address2"}},[_c('validation-provider',{attrs:{"name":"address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address2","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入地址2"},model:{value:(_vm.form.address_line_2),callback:function ($$v) {_vm.$set(_vm.form, "address_line_2", $$v)},expression:"form.address_line_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"地址3","label-for":"address3"}},[_c('validation-provider',{attrs:{"name":"address3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address3","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入地址3"},model:{value:(_vm.form.address_line_3),callback:function ($$v) {_vm.$set(_vm.form, "address_line_3", $$v)},expression:"form.address_line_3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":!_vm.isTw ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"电话","label-for":"rphone"}},[_c('validation-provider',{attrs:{"name":"rphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":'+' + _vm.form.phone_code}},[_c('b-form-input',{attrs:{"disabled":!_vm.isEdit,"placeholder":"请输入电话号码","type":"number"},model:{value:(_vm.form.contact_number),callback:function ($$v) {_vm.$set(_vm.form, "contact_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.contact_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":!_vm.isTw ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"邮箱","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入邮箱"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),(_vm.emailError)?_c('small',{staticClass:"text-danger"},[_vm._v("邮箱格式不正确，请检查")]):_vm._e()]}}])})],1)],1),(_vm.isTw)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"身份证","label-for":"id_card"}},[_c('validation-provider',{attrs:{"name":"id_card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_card","disabled":!_vm.isEdit,"state":errors.length > 0 ? false:null,"placeholder":"请输入身份证"},model:{value:(_vm.form.id_card),callback:function ($$v) {_vm.$set(_vm.form, "id_card", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.id_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.idCardError)?_c('small',{staticClass:"text-danger"},[_vm._v("请输入正确的台湾身份证号")]):_vm._e()]}}],null,false,1642175310)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }