<template>
  <b-card>
    <div class="card-h3 d-flex justify-content-between align-items-center">
      <h3 class="card-title">
        额外服务(选择{{ extraServices.length }}个)
      </h3>
      <b-button
        v-if="shipment_status === 'paid_agent'"
        variant="relief-primary"
        @click="handleModifyEs"
      >
        修改额外服务
      </b-button>
    </div>
    <b-table
      ref="selectableTable"
      class="mt-2"
      selectable
      :items="items"
      :fields="fields"
      responsive
      selectMode="single"
    />
    <b-modal
      ref="modal-extra-service"
      cancel-variant="outline-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      size="xl"
      title="自定义额外服务"
      @ok="handleSaveExtraService"
    >
      <div class="mb-2 d-flex justify-content-end">
        <b-button
          variant="relief-primary"
          @click="handleAddDiy"
        >
          添加自定义额外服务
        </b-button>
      </div>
      <b-overlay
        :show="reOverShow"
        rounded="sm"
      >
        <extra-service
          ref="extraservice"
          :extraServices="extraServicesList"
        />
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BOverlay,
} from 'bootstrap-vue'
import ExtraService from '../../agent/components/extraService.vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BOverlay,
    ExtraService,
  },
  props: {
    extraServices: {
      type: Array,
      default: () => [],
    },
    shipment_status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: '服务名称' },
        { key: 'price', label: '价格(AU$)' },
        { key: 'extra_service_qty', label: '数量' },
        { key: 'charged_per', label: '收费单位' },
      ],
      reOverShow: false,
    }
  },
  computed: {
    detail() {
      return this.$store.state.shipment.detail
    },
    extraServicesList({ extraServices }) {
      return extraServices.map(item => ({
        id: item.id,
        name: item.name,
        price: item.price,
        qty: item.extra_service_qty,
        charged_per: item.charged_per,
      }))
    },
  },
  created() {
    this.items = [].concat(this.extraServices)
  },
  methods: {
    handleModifyEs() {
      this.$refs['modal-extra-service'].show()
    },
    handleAddDiy() {
      this.$nextTick(() => {
        this.$refs.extraservice.handleAddDiy()
      })
    },
    async handleSaveExtraService(bvModalEvt) {
      bvModalEvt.preventDefault()
      const exList = this.$refs.extraservice.items.map(item => ({
        id: item.id < 10000 ? item.id : -1,
        name: item.name,
        qty: item.qty,
        charged_per: item.charged_per,
        price: Number(item.price),
      }))
      const params = {
        shipment: this.detail.id,
        shipment_extra_services: exList,
      }
      this.handleModify(params)
    },
    async handleModify(params) {
      try {
        this.reOverShow = true
        const data = await this.$api.modifyShipmentExtraService(params)
        if (data.code === 200) {
          this.reOverShow = false
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
          this.$bvToast.toast('修改额外服务成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.timer = setTimeout(() => {
            this.$refs['modal-extra-service'].hide()
          }, 2000)
        } else {
          this.reOverShow = false
          this.$bvToast.toast(data.message, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
        this.reOverShow = false
        this.$bvToast.toast(error, {
          title: '提示',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.service-box{
  border:1px solid #808080;
}
.info{
  font-size:18px;
  border-top:1px solid #808080;
  span{
    width:50%;
    text-align: center;
    line-height: 40px;
  }
}
.total{
  border-left:1px solid #808080;
}
</style>
