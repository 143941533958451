<template>
  <b-card>
    <h3 class="mb-3 card-title border-b">
      备注
    </h3>
    <div
      v-for="item in shipmentComments"
      :key="item.id"
      class="media-wrap"
    >
      <b-media
        v-if="userInfo.id !== item.uid"
        vertical-align="top"
      >
        <h4 class="media-heading">
          {{ item.user }}
          <span class="time">{{ item.createAt }}</span>
        </h4>
        <b-card-text class="mb-0">
          {{ item.comment }}
        </b-card-text>
        <div class="d-flex align-items-center justify-content-start">
          <viewer :images="item.file">
            <img
              v-for="v in item.file"
              :key="v.path"
              class="img"
              :src="v.path"
            >
          </viewer>
        </div>
      </b-media>
      <b-media
        v-else
        right-align
        vertical-align="top"
        class="text-right"
      >
        <h4 class="media-heading">
          {{ item.user }}
          <span class="time">{{ item.createAt }}</span>
        </h4>
        <b-card-text class="mb-0">
          {{ item.comment }}
        </b-card-text>
        <viewer :images="item.file">
          <div class="d-flex align-items-center justify-content-end">
            <img
              v-for="v in item.file"
              :key="v"
              class="img"
              :src="v"
            >
          </div>
        </viewer>
      </b-media>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BMedia, BCardText,
  },
  props: {
    shipmentComments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userInfo: {},
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      try {
        const user = localStorage.getItem('userInfo')
        this.userInfo = JSON.parse(user)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.media-heading{
  font-weight: bold;
  font-size: 18px;
}
.time{
  font-size: 16px;
  color: #808080;
  margin-left: 10px;
  font-weight: normal;
}
.media-wrap:not(:last-child){
  border-bottom: 1px dashed #ebe9f1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.img{
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
}
</style>
