<template>
  <!-- outline color -->
  <div class="d-flex justify-content-start">
    <b-card style="width:50%;">
      <div class="border-b d-flex justify-content-between align-items-center">
        <h1 class="mb-0 card-title">
          <span>发件人信息 </span>
          <span>{{ detail.shipment_id }}</span>
        </h1>
        <b-button
          v-if="detail.lineclear === 1 && detail.shipment_status === 'paid_agent'"
          variant="relief-primary"
          @click="$emit('handleCreateLineclear')"
        >
          创建Lineclear
        </b-button>
        <b-button
          v-else-if="detail.lineclear === 2 && detail.shipment_status === 'paid_agent'"
          variant="relief-primary"
          class="ml-1"
          @click="$emit('handleCreateLineclear')"
        >
          重新创建Lineclear
        </b-button>
        <!-- 占位 -->
        <div
          v-else
          class="btn-placeholder-block"
        />
      </div>
      <div class="info-text mt-1">
        <span>提交日期: </span>
        <span>{{ detail.create_time }}</span>
      </div>
      <div class="info-text mt-1">
        <span>发件人: </span>
        <span>{{ detail.sender_name }}</span>
      </div>
      <div class="info-text mt-1">
        <span>发件人电话: </span>
        <span>+{{ detail.sender_phone_code }} {{ detail.sender_mobile }}</span>
      </div>
      <div class="info-text mt-1">
        <span>代理点: </span>
        <span>{{ detail.agent_name }}</span>
      </div>
      <div class="info-text mt-1">
        <span>预报单: </span>
        <span>{{ detail.agent_order_id }}</span>
      </div>
    </b-card>
    <b-card style="margin-left:20px;width:calc(50% - 20px);">
      <div class="border-b d-flex align-items-center justify-content-between">
        <h1 class="mb-0 card-title">
          收件人信息
        </h1>
        <b-button
          v-if="detail.shipment_status === 'paid_agent'"
          variant="relief-primary"
          @click="handleShowReModal"
        >
          修改收件人
        </b-button>
      </div>
      <div class="info-text mt-1">
        <span>姓名: </span>
        {{ detail.recipient && detail.recipient.name }}
      </div>
      <div class="info-text mt-1">
        <span>手机号: +{{ detail.recipient && detail.recipient.phoneCode }} {{ detail.recipient && detail.recipient.contactNumber }} </span>
      </div>
      <div class="info-text mt-1">
        <span>国家: </span>
        {{ detail.recipient && detail.recipient.country }}
      </div>
      <div
        v-if="detail.recipient && detail.recipient.company_name"
        class="info-text mt-1"
      >
        <span>公司名称: </span>
        {{ detail.recipient.company_name }}
      </div>
      <div
        v-if="detail.recipient && detail.recipient.address"
        class="info-text mt-1"
      >
        <span>地址1: </span>
        {{ detail.recipient.address }}
      </div>
      <div
        v-if="detail.recipient && detail.recipient.address_line_2"
        class="info-text mt-1"
      >
        <span>地址2: </span>
        {{ detail.recipient.address_line_2 }}
      </div>
      <div
        v-if="detail.recipient && detail.recipient.address_line_3"
        class="info-text mt-1"
      >
        <span>地址3: </span>
        {{ detail.recipient.address_line_3 }}
      </div>
      <div class="info-text mt-1">
        <span>邮编地区: </span>
        {{ detail.recipient && detail.recipient.suburb }},{{ detail.recipient && detail.recipient.postcode }},{{ detail.recipient && detail.recipient.state }}
      </div>
      <div
        v-if="detail.recipient && detail.recipient.email"
        class="info-text mt-1"
      >
        <span>Email: </span>
        {{ detail.recipient.email }}
      </div>
    </b-card>
    <b-modal
      ref="modal-receiver"
      cancel-variant="outline-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      title="修改收件人"
      size="lg"
      @ok="handleSaveReceiver"
    >
      <b-overlay
        :show="reOverShow"
        rounded="sm"
      >
        <receiver
          ref="receiver"
          isEdit
          isShipmentDetail
          :recipient="detail.recipient"
        />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, BOverlay,
} from 'bootstrap-vue'
import Receiver from '../../agent/components/receiver.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BOverlay,
    Receiver,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reOverShow: false,
    }
  },
  methods: {
    handleShowReModal() {
      this.$refs['modal-receiver'].show()
    },
    async handleSaveReceiver(bvModalEvt) {
      try {
        bvModalEvt.preventDefault()
        this.reOverShow = true
        const { form } = this.$refs.receiver
        const params = {
          ...form,
          shipment_id: this.detail.id,
          phone: form.contact_number,
          address1: form.address,
          address2: form.address_line_2,
          address3: form.address_line_3,
        }
        const data = await this.$api.modifyRecipientOfShipment(params)
        if (data.code === 200) {
          this.reOverShow = false
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
          this.$bvToast.toast('修改收件人成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.timer = setTimeout(() => {
            this.$refs['modal-receiver'].hide()
          }, 2000)
        }
      } catch (error) {
        console.log('error', error)
        this.reOverShow = false
        this.$bvToast.toast(error, {
          title: '提示',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.info-text{
  font-size:18px;
}
.phone{
  font-size:20px;
}
.personal-info{
  font-size:18px;
}
.personal-info-fir{
  margin-top:2.5rem;
  font-size:20px;
}
</style>
