<template>
  <b-card>
    <div class="mb-2 card-h3 d-flex justify-content-between align-items-center">
      <h3 class="card-title">
        包裹申报
      </h3>
      <b-button
        v-if="detail.shipment_status === 'paid_agent'"
        variant="relief-primary"
        @click="handleClickDeclare"
      >
        修改包裹申报
      </b-button>
    </div>
    <b-table
      ref="selectableTable"
      selectable
      :items="detail.shipmentDeclarationForm"
      :fields="fields"
      responsive
      selectMode="single"
    >
      <template #cell(value)="data">
        <span class="text-nowrap">
          AU${{ Number(data.value).toFixed(2) }}
        </span>
      </template>
    </b-table>
    <div class="declare-title">
      申报金额: AU${{ Number(declareTotalPrice).toFixed(2) }}
    </div>
    <b-modal
      ref="modal-declare"
      cancel-variant="outline-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      size="xl"
      title="修改包裹申报"
      @ok="handleSaveDeclare"
      @close="closeDeclareModal"
      @cancel="closeDeclareModal"
    >
      <b-overlay
        :show="reOverShow"
        rounded="sm"
      >
        <declare
          v-if="isShowDeclare"
          ref="declare"
          :declare="detail.shipmentDeclarationForm"
        />
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BOverlay,
} from 'bootstrap-vue'
import Declare from '../../agent/components/declare.vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    Declare,
    BOverlay,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: '产品名称' },
        { key: 'qty', label: '数量' },
        { key: 'value', label: '总价值' },
      ],
      isShowDeclare: false,
      reOverShow: false,
    }
  },
  computed: {
    declareTotalPrice({ detail }) {
      if (detail.shipmentDeclarationForm && detail.shipmentDeclarationForm.length) {
        return detail.shipmentDeclarationForm.reduce((total, item) => total + Number(item.value), 0)
      }
      return 0
    },
  },
  methods: {
    handleClickDeclare() {
      this.$refs['modal-declare'].show()
      // 延迟包裹申报组件渲染
      setTimeout(() => {
        this.isShowDeclare = true
      }, 300)
    },
    async handleSaveDeclare(bvModalEvt) {
      bvModalEvt.preventDefault()
      try {
        let declare_goods = this.$refs.declare.items
        declare_goods = declare_goods.map(item => ({
          name: item.name,
          qty: item.qty,
          value: Number(item.value),
        }))
        const params = {
          shipment_id: this.detail.id,
          declare_goods,
        }
        this.reOverShow = true
        const data = await this.$api.modifyDeclareOfShipment(params)
        if (data.code === 200) {
          this.reOverShow = false
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
          this.$bvToast.toast('修改包裹申报成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          setTimeout(() => {
            this.$refs['modal-declare'].hide()
            this.isShowDeclare = false
          }, 2000)
        } else {
          this.reOverShow = false
          this.$bvToast.toast(data.message, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    closeDeclareModal() {
      this.$refs['modal-declare'].hide()
      this.isShowDeclare = false
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.price-text{
  font-size:20px;
}
.ins{
  font-size:20px;
}
.check-wrap ::v-deep .custom-control-label::after,.check-wrap ::v-deep .custom-control-label::before{
  top:5px;
}
.declare-title{
  font-size: 18px;
  text-align: right;
  border-top:1px solid #ebe9f1;
  padding-top: 20px;;
}
</style>
