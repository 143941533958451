<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center card-h3">
      <h3 class="card-title mb-0 d-flex justify-content-start align-items-center">
        <span>运单概览</span>
      </h3>
      <h6 class="sub-title right-title">
        计费重量: {{ detail.packagesChargeableWeight }}kg
      </h6>
    </div>
    <b-overlay
      :show="tableOverShow"
      rounded="sm"
    >
      <b-table
        responsive
        striped
        :items="detail.packages"
        :fields="fields"
        class="mb-0 mt-2"
      >
        <template #cell(index)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(dead_weight)="data">
          <b-form-input
            v-model="data.value"
            class="plainTextInput"
            :plaintext="isPlaintext"
            @blur="handleInteger(data)"
          />
        </template>

        <template #cell(length)="data">
          <b-form-input
            v-model="data.value"
            class="plainTextInput"
            :plaintext="isPlaintext"
            @blur="handleInteger(data)"
          />
        </template>

        <template #cell(width)="data">
          <b-form-input
            v-model="data.value"
            class="plainTextInput"
            :plaintext="isPlaintext"
            @blur="handleInteger(data)"
          />
        </template>

        <template #cell(height)="data">
          <b-form-input
            v-model="data.value"
            class="plainTextInput"
            :plaintext="isPlaintext"
            @blur="handleInteger(data)"
          />
        </template>

        <template #cell(volumetric_weight)="data">
          <span class="text-nowrap">
            {{ Number(data.value).toFixed(2) }}
          </span>
        </template>

        <template #cell(chargeable_weight)="data">
          <span class="text-nowrap">
            {{ Number(data.value).toFixed(2) }}
          </span>
        </template>

        <template #cell(operation)="data">
          <feather-icon
            v-if="!isPlaintext && detail.packages.length > 1 && detail.shipment_status === 'paid_agent'"
            icon="XIcon"
            size="28"
            class="red-icon"
            @click="handleDeletePackage(data)"
          />
          <feather-icon
            v-if="!isPlaintext"
            icon="PlusIcon"
            size="28"
            class="yellow-icon ml-1"
            @click="handleAddPackage"
          />
        </template>
      </b-table>
    </b-overlay>
    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-if="detail.shipment_status === 'paid_agent' && isPlaintext"
        variant="relief-primary"
        @click="isPlaintext = false"
      >
        编辑
      </b-button>
      <b-button
        v-if="!tableOverShow && detail.shipment_status === 'paid_agent' && !isPlaintext"
        variant="relief-success"
        @click="handleSavePackage"
      >
        保存
      </b-button>
      <b-button
        v-if="tableOverShow && detail.shipment_status === 'paid_agent' && !isPlaintext"
        variant="relief-success"
      >
        保存中...
      </b-button>
    </div>
    <!-- <b-modal
      ref="modal-package"
      cancel-variant="outline-secondary"
      :ok-title="reOverShow ? '处理中...' : '保存'"
      cancel-title="取消"
      centered
      :title="modalPackageText"
      @ok="handleSavePackage"
    >
      <package
        ref="package"
      />
    </b-modal> -->
    <b-modal
      ref="modal-print"
      cancel-variant="outline-secondary"
      centered
      title="打印运单"
    >
      <h3 class="text-center">
        由于您修改了包裹，当前价格已变更，需要您重新打印运单或者生成Lineclear
      </h3>
      <div class="mt-3 mb-2 text-center">
        当前价格为: AU${{ detail.totalEstimateCost }}
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-if="detail.lineclear === 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            class="float-right"
            @click="handlePrint"
          >
            打印运单
          </b-button>
          <b-button
            v-if="printLineclear"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-primary"
            class="float-right"
            @click="handlePrint"
          >
            打印Lineclear
          </b-button>
          <b-button
            v-if="!printLineclear && !isLineclearLoading && detail.lineclear === 1 && detail.shipment_status === 'paid_agent'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-success"
            class="float-right ml-2"
            @click="handleCreateLineclear"
          >
            创建Lineclear
          </b-button>
          <b-button
            v-if="!printLineclear && !isLineclearLoading && detail.lineclear === 2 && detail.shipment_status === 'paid_agent'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-success"
            class="float-right ml-2"
            @click="handleCreateLineclear"
          >
            重新创建Lineclear
          </b-button>
          <b-button
            v-if="isLineclearLoading"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-success"
            class="float-right ml-2"
          >
            创建Lineclear中...
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="modal-del-package"
      cancel-variant="outline-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="提示"
      @ok="handleDelPackage"
    >
      <span>确认要删除这个运单包裹吗？</span>
    </b-modal>
    <b-modal
      ref="modal-courier"
      cancel-variant="outline-secondary"
      :ok-title="courierShow ? '处理中...' : '保存'"
      cancel-title="取消"
      size="xl"
      centered
      title="请重新选择物流线路"
      @ok="handleUpdateShipmentCourier"
      @hidden="handleCancelCourier"
    >
      <b-table
        ref="selectableTable"
        selectable
        :items="couriers"
        :fields="couriersFields"
        responsive="sm"
        selectMode="single"
        @row-selected="onRowSelected"
      >
        <template #row-details="row">
          <b-card
            class="shadow-none"
          >
            <b-row class="mb-2">
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>物流附加费用 : </strong>AU${{ row.item.extra_fee }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>海关费用 : </strong>AU${{ row.item.tax_fee }}
              </b-col>
            </b-row>
          </b-card>
        </template>
        <template #cell(radio)="data">
          <b-form-radio
            v-model="data.value"
            plain
            name="radio"
            size="lg"
            :value="data.item.id"
            @change="handleRadioChange"
          />
        </template>
        <template #cell(logo)="data">
          <b-avatar
            variant="light-secondary"
            size="50px"
            square
            :src="data.value"
          />
        </template>
        <template #cell(estimation)="data">
          <span>AU${{ data.value }}</span>
        </template>
        <template #cell(duration)="data">
          <span>{{ data.value }}天</span>
        </template>
        <template #cell(include_tax)="data">
          <span>{{ data.value == 1 ? '是' : '否' }}</span>
        </template>
        <template #cell(weight)="data">
          <span>{{ data.value }}KG</span>
        </template>
        <template #cell(other)="data">
          <span>{{ data.item.weight_calc_type_text }}</span>
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BOverlay, BFormRadio, BCol, BRow, BAvatar, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import Package from './package.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BButton,
    BOverlay,
    // Package,
    BFormRadio,
    BCol,
    BRow,
    BAvatar,
    BFormInput,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    isLineclearLoading: {
      type: Boolean,
      default: false,
    },
    printLineclear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'package_no', label: 'No.',
        },
        {
          key: 'dead_weight', label: '实际重量(kg)',
        },
        {
          key: 'length', label: '长(cm)',
        },
        {
          key: 'width', label: '宽(cm)',
        },
        {
          key: 'height', label: '高(cm)',
        },
        {
          key: 'volumetric_weight', label: '体积重(kg)',
        },
        {
          key: 'chargeable_weight', label: '计费重量(kg)',
        },
        {
          key: 'operation', label: '操作',
        },
      ],
      couriersFields: [
        { key: 'radio', label: '' },
        { key: 'logo', label: '' },
        { key: 'name', label: '物流线路' },
        { key: 'estimation', label: '预估运费' },
        { key: 'dispatch_date', label: '下次发货日期' },
        { key: 'duration', label: '运输时间' },
        { key: 'include_tax', label: '是否含税' },
        { key: 'weight', label: '计费重量' },
        { key: 'other', label: '其他信息' },
      ],
      // reOverShow: false,
      tableOverShow: false,
      delItem: {},
      couriers: [],
      courierShow: false,
      shipment_packages: [],
      selected: [],
      // editPackageNo: '',
      // editPackageInfo: {},
      isPlaintext: true,
    }
  },
  // computed: {
  //   modalPackageText() {
  //     return this.editPackageNo ? '修改运单包裹' : '新增运单包裹'
  //   },
  // },
  methods: {
    handleCreateLineclear() {
      this.$emit('handleCreateLineclear')
    },
    handleCancelCourier() {
      this.tableOverShow = false
      this.courierShow = false
    },
    handlePrint() {
      this.$refs['modal-print'].hide()
      this.$emit('handlePrintModal')
    },
    handleInteger(data) {
      this.detail.packages[data.index][data.field.key] = Math.abs(data.value).toFixed(2)
    },
    // handleEditPackage(item) {
    //   this.editPackageInfo = item
    //   this.editPackageNo = item.package_no
    //   this.$refs['modal-package'].show()
    // },
    onRowSelected(item) {
      if (item.length) {
        this.couriers.forEach(v => {
        // eslint-disable-next-line
          v.radio = item[0].id
        })
        this.selected = item
      } else if (this.selected.length) {
        this.handleRadioChange(this.selected[0].id)
      }
    },
    handleRadioChange(e) {
      let idx
      this.items.forEach((v, n) => {
        // eslint-disable-next-line
        if (v.id == e) {
          idx = n
        }
      })
      this.$refs.selectableTable.selectRow(idx)
    },
    async handleUpdateShipmentCourier(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.selected.length === 0) {
        this.$bvModal
          .msgBoxConfirm('请选择线路', {
            cancelVariant: 'outline-danger',
            centered: true,
            buttonSize: 'sm',
          })
        return
      }
      try {
        const params = {
          shipment_id: this.detail.id,
          courier_id: this.selected[0].id,
          shipment_packages: this.shipment_packages,
        }
        this.courierShow = true
        const data = await this.$api.updateShipmentCourier(params)
        if (data.code === 200) {
          this.courierShow = false
          this.isPlaintext = true
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
          this.$bvToast.toast('修改线路成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.$refs['modal-courier'].hide()
          this.timer = setTimeout(() => {
            this.$refs['modal-print'].show()
          }, 2000)
        } else {
          this.courierShow = false
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    handleAddPackage() {
      // this.$refs['modal-package'].show()
      this.detail.packages.push({
        length: '',
        width: '',
        height: '',
        dead_weight: '',
        volumetric_weight: '',
        chargeable_weight: '',
        package_no: this.handlePackageNo(),
      })
    },
    handlePackageNo() {
      const prevWaybillNumber = this.detail.packages[this.detail.packages.length - 1].package_no
      const waybillNumber = prevWaybillNumber.slice(0, -3) + String(Number(prevWaybillNumber.slice(-3)) + 1).padStart(3, '0')
      return waybillNumber
    },
    async handleSavePackage() {
      const isVerify = this.detail.packages.every(v => v.dead_weight && v.length && v.width && v.height)
      if (!isVerify) {
        this.$bvToast.toast('包裹重量和尺寸必填', {
          title: '提示',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
        return
      }
      this.detail.packages.forEach((v, idx) => {
        // eslint-disable-next-line
        v.package_no = this.detail.packages[0].package_no.slice(0, -3) + String(idx + 1).padStart(3, '0')
      })
      const packages = this.detail.packages.map(item => ({
        length: Number(Number(item.length).toFixed(2)),
        width: Number(Number(item.width).toFixed(2)),
        height: Number(Number(item.height).toFixed(2)),
        weight: Number(Number(item.dead_weight).toFixed(2)),
        package_no: item.package_no,
      }))

      this.shipment_packages = packages
      const params = {
        shipment_packages: packages,
        shipment_id: this.detail.id,
      }
      this.tableOverShow = true
      this.handleModify(params)
    },
    async handleModify(params) {
      try {
        // this.reOverShow = true
        const data = await this.$api.modifyShipmentPackages(params)
        if (data.code === 200) {
          // this.reOverShow = false
          this.tableOverShow = false
          this.isPlaintext = true
          this.$store.dispatch('shipment/getShipmentDetail', this.detail.id)
          this.$bvToast.toast('修改运单包裹成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.timer = setTimeout(() => {
            this.$refs['modal-print'].show()
          }, 2000)
        } else if (data.code === 40022) {
          this.couriers = data.data.couriers
          // this.$refs['modal-package'].hide()
          this.$refs['modal-courier'].show()
        } else {
          // this.reOverShow = false
          this.tableOverShow = false
          this.isPlaintext = true
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
        // this.reOverShow = false
        this.tableOverShow = false
        this.$bvToast.toast(error, {
          title: '提示',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }
    },
    handleDeletePackage(item) {
      this.delItem = item
      this.$refs['modal-del-package'].show()
    },
    handleDelPackage() {
      this.detail.packages = this.detail.packages.filter((v, idx) => idx !== this.delItem.index)
      // packages = packages.map(v => ({
      //   length: Number(v.length),
      //   width: Number(v.width),
      //   height: Number(v.height),
      //   weight: Number(v.chargeable_weight),
      // }))
      // const params = {
      //   shipment_packages: packages,
      //   shipment_id: this.detail.id,
      // }
      // this.tableOverShow = true
      // this.handleModify(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.red-icon, .yellow-icon{
  color: #FF5B5C;
  cursor: pointer;
}
.yellow-icon{
  margin-right: 20px;
  color: #FFAF2A;
}
.sub-title{
  font-size: 16px;
  color: #000;
  font-weight: normal;
}
.right-title{
  line-height: 41.8px;
  margin: 0;
}
.plainTextInput{
  width: 100px;
}
</style>
