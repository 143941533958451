<template>
  <b-card v-if="shipmentLogs.length">
    <h3 class="mb-1 card-title border-b">
      运单日志
    </h3>
    <b-table
      responsive
      striped
      :items="shipmentLogs"
      :fields="fields"
      class="mb-0 mt-2"
    >
      <template #cell(index)="data">
        <span class="text-nowrap">
          {{ data.index + 1 }}
        </span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    shipmentLogs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'index', label: 'No.',
        },
        {
          key: 'status_note', label: '运单状态',
        },
        {
          key: 'operator', label: '操作者',
        },
        {
          key: 'createAt', label: 'Time',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

</style>
