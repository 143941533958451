<template>
  <!-- outline color -->
  <div class="d-flex justify-content-start">
    <b-card style="width:60%;">
      <div class="border-b d-flex align-items-center justify-content-between">
        <h1 class="mb-0 card-title">
          快递信息
        </h1>
        <div class="btn-placeholder-block" />
      </div>
      <div class="d-flex justify-content-between mt-1">
        <div class="left">
          <h3 class="mb-1 info-text">
            {{ detail.courier && detail.courier.name }}
          </h3>
          <div class="info-text">
            <span>追踪号码: </span>
            <span>{{ detail.trackData && detail.trackData[0].no || 'N/A' }}</span>
            <span
              v-if="detail.trackData && detail.trackData[0].no"
              class="ml-1 watch-track"
              @click="handleWatchMore"
            >查看更多</span>
          </div>
          <div class="info-text d-flex align-items-center">
            <div>
              <span>发货包裹: </span>
              <span>{{ detail.packagesNum }}个</span>
            </div>
          </div>
          <div class="info-text">
            <span>计费重量: </span>
            <span>{{ detail.packagesChargeableWeight }}kg</span>
          </div>
          <div class="info-text">
            <span>是否含税: </span>
            <span>{{ detail.courier && detail.courier.includeTax === 0 ? 'DDU' : 'DDP' }}
              <feather-icon
                icon="InfoIcon"
                class="info-icon"
                @click="handleInfoIcon(detail.courier && detail.courier.includeTax)"
              />
            </span>
          </div>
          <div class="info-text">
            <span>运输时间: </span>
            <span>{{ detail.courier && detail.courier.duration }}天</span>
          </div>
          <div class="info-text">
            <span>计费方式: </span>
            <span>一票多单，实际重量和体积重，取大值</span>
          </div>
        </div>
        <div class="right">
          <img
            class="img"
            :src="detail.courier ? detail.courier.logo : ''"
          >
        </div>
      </div>
    </b-card>
    <b-card style="margin-left:20px;width:calc(40% - 20px);">
      <div class="border-b d-flex align-items-center justify-content-between">
        <h1 class="mb-0 card-title">
          费用小计
        </h1>
        <div class="btn-placeholder-block" />
      </div>
      <div
        v-if="Number(detail.estimatedCost)"
        class="info-text d-flex justify-content-between mt-1"
      >
        <span>物流费用</span>
        <span>AU${{ detail.estimatedCost }}</span>
      </div>
      <div
        v-if="Number(detail.endDeliveryCost)"
        class="info-text d-flex justify-content-between"
      >
        <span>派送费用</span>
        <span>AU${{ detail.endDeliveryCost }}</span>
      </div>
      <div
        v-if="Number(detail.insuranceCharge)"
        class="info-text d-flex justify-content-between"
      >
        <span>保险</span>
        <span>AU${{ detail.insuranceCharge }}</span>
      </div>
      <div
        v-if="Number(detail.extraServiceCharge)"
        class="info-text d-flex justify-content-between"
      >
        <span>额外服务</span>
        <span>AU${{ detail.extraServiceCharge }}</span>
      </div>
      <div
        v-if="Number(detail.tax_fee)"
        class="info-text d-flex justify-content-between"
      >
        <span>税费<feather-icon
          icon="InfoIcon"
          class="info-icon"
          @click="handleTaxIcon(detail)"
        /></span>
        <span>AU${{ detail.tax_fee }}</span>
      </div>
      <div
        v-if="Number(detail.discount)"
        class="info-text d-flex justify-content-between"
      >
        <span>价格调整</span>
        <span>-AU${{ (Math.abs(Number(detail.discount))).toFixed(2) }}</span>
      </div>
      <div
        v-if="Number(detail.extra_fee)"
        class="info-text d-flex justify-content-between"
      >
        <span>物流附加费用</span>
        <span>AU${{ (Number(detail.extra_fee)).toFixed(2) }}</span>
      </div>
      <hr class="hr">
      <div class="info-text d-flex justify-content-between align-items-center">
        <span class="total-text">总计</span>
        <span>AU${{ detail.totalEstimateCost }}</span>
      </div>
    </b-card>
    <b-modal
      ref="modal-track"
      cancel-variant="outline-secondary"
      centered
      title="追踪信息"
    >
      <b-table
        responsive
        striped
        :items="detail.trackData"
        :fields="fields"
        class="mb-0 mt-2"
      >
        <template #cell(operation)="data">
          <span
            class="watch-track"
            @click="handleWatch(data)"
          >追踪</span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BModal, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BModal,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'no',
          label: '追踪号码',
        },
        {
          key: 'operation',
          label: '操作',
        },
      ],
    }
  },
  methods: {
    handleWatchMore() {
      this.$refs['modal-track'].show()
    },
    handleWatch(data) {
      window.open(data.item.url)
    },
    handleInfoIcon(isTax) {
      const title = isTax === 0 ? '不含税' : '含税'
      this.$swal({
        title,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleTaxIcon(detail) {
      let html = ''
      detail.rates.forEach(item => {
        html += `<span style="font-size: 24px;display: block;line-height:40px;">${item.name}: AUD$${item.base_amount} * ${item.percent}% = AU$${item.amount}</span>`
      })
      this.$swal({
        html,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'my-swal-popup',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
.my-swal-popup{
  width: 900px !important;
}
</style>

<style lang="scss" scoped>
.info-text{
  font-size:18px;
  position:relative;
  &:not(:last-child){
    margin-bottom:10px;
  }
}
.watch-btn{
  position:absolute !important;
  left: 10rem;
  top: -0.5rem;
  width: 150px;
}
.right{
  width:8rem;
  height:8rem;
  margin: 10px 10px 0 0;
}
.img{
  display: block;
  width: 100%;
}
.hr{
  border-top:1px solid #808080;
}
.total-text{
  font-size:18px;
  font-weight: bold;
}
.info-icon{
  color:#FFAF2A;
  position:relative;
  top:-2px;
  width:18px;
  height:18px;
  cursor: pointer;
}
.watch-track{
  color: #FFAF2A;
  text-decoration: underline;
  cursor: pointer;
}
</style>
